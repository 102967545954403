import React, { useState } from "react";
import { Link } from "react-router-dom";

import VisibilitySensor from "react-visibility-sensor";

import logo from "src/assets/icon/logo.png"

function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div className={`footer-bg-lp ${isVisible ? "visible" : ""}`}>
        <div className="footer">
          <div className="logo">
            <img
              className={`${isVisible ? "visible" : ""}`}
              src={logo}
              alt="Logotipo da empresa"
              width={"250px"}
            />
            <div className={`line ${isVisible ? "visible" : ""}`}></div>
          </div>
          <div className={`address ${isVisible ? "visible" : ""}`}>
            <p>R. Francisco Reis, 1402 - Cordeiros, Itajaí - SC, 88311-750</p>
          </div>
          <div className={`rights ${isVisible ? "visible" : ""}`}>
            <p>
              © 2024 - GH Solucionador Logístico | Todos os Direitos Reservados
            </p>
          </div>
          <div className={`singlec ${isVisible ? "visible" : ""}`}>
            <p>
              A product developed by{" "}
              <a
                href="https://singlec.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Singlec
              </a>
            </p>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default Footer;
