import Navbar from "../../components/Navbar";
import MediaButtons from "src/components/MediaButtons";
import Footer from "../../components/Footer";
import ButtonTop from "src/components/ButtonTop";

import SectionOne from "./components/sectionOne";
import SectionTwo from "./components/sectionTwo";
import SectionThree from "./components/sectionThree";
import SectionFour from "./components/sectionFour";
import SectionFive from "./components/sectionFive";

function LandingPageOne() {
  return (
    <div id="landingPageOne">
      <Navbar></Navbar>
      <MediaButtons></MediaButtons>
      <ButtonTop></ButtonTop>
      <SectionOne></SectionOne>
      <SectionTwo id="about"></SectionTwo>
      <SectionThree id="benefits"></SectionThree>
      <SectionFour id="contact"></SectionFour>
      <SectionFive id="fleet"></SectionFive>
      <Footer></Footer>
    </div>
  );
}

export default LandingPageOne;
