import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../../assets/img/home/career/img2.webp";

function SectionTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>
      <div id="about" className={`sectionTwo ${isVisible ? "visible" : ""}`}>
        <div className="arrows1">
          <img
            className={`img1 ${isVisible ? "visible" : ""}`}
            src={img1}
            alt=""
            width={"160px"}
          />
          <img
            className={`img2 ${isVisible ? "visible" : ""}`}
            src={img1}
            alt=""
            width={"160px"}
          />
        </div>
        <div className="text">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Sobre a vaga
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Se você é um motorista com mais de um ano de experiência e reside 
            em um raio de até 30 km de Curitiba, essa é a sua oportunidade!
            Na GH Operador Logístico, você será parte de uma equipe que valoriza 
            seu trabalho e investe no seu crescimento. Trabalhamos com tecnologia 
            de ponta e um compromisso real com a segurança e bem-estar dos nossos 
            motoristas.
            Clique no botão abaixo e comece sua jornada com a GH Operador Logístico!
          </p>
          <a href="#contact" className={`${isVisible ? "visible" : ""}`}>
            <button>Candidatar-se</button>
          </a>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default SectionTwo;
