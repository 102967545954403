import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";

import arrowLeft from "../../../../assets/img/components/arrow-left.webp";
import arrowRight from "../../../../assets/img/components/arrow-right.webp";

import img1 from "../../../assets/img/landingPageOne/sectionThree/pagar.png";
import img2 from "../../../assets/img/landingPageOne/sectionThree/bonus.png";
import img3 from "../../../assets/img/landingPageOne/sectionThree/saude.png";
import img4 from "../../../assets/img/landingPageOne/sectionThree/treinamento.png";
import img5 from "../../../assets/img/landingPageOne/sectionThree/uniforme.png";
import img6 from "../../../assets/img/landingPageOne/sectionThree/desconto.png";
import img7 from "../../../assets/img/landingPageOne/sectionThree/dente.png";
import img8 from "../../../assets/img/landingPageOne/sectionThree/familia.png";

function SectionThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const breakpoints = {
    1200: {
      slidesPerView: 4,
    },
    1000: {
      spaceBetween: 20,
    },
    900: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={200}>
      <div id="benefits" className={`sectionThree ${isVisible ? "visible" : ""}`}>
        <div className="sectionThreeTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Benefícios Oferecidos
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Na GH, você tem acesso a benefícios diferenciados que tornam seu dia a dia mais valorizado.
          </p>
        </div>
        <Swiper
          slidesPerView={4}
          spaceBetween={40}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          speed={500}
          loop={true}
          modules={[Autoplay, Navigation]}
          breakpoints={breakpoints}
          className={`${isVisible ? "visible" : ""}`}
        >
          <div className={`swiper-button-prev`}>
            <img src={arrowLeft} width={"40px"} alt="" />
          </div>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img1} alt="" />
              <p>
                <span>Pagamentos competitivos:</span> diárias e horas extras
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img2} alt="" />
              <p>
                Plano de bonificação: GHecodriving (condução eficiente)
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img3} alt="" />
              <p>
                Seguro de Vida
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img4} alt="" />
              <p>
                Treinamentos e cuidados de saúde: Sest Senat
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img5} alt="" />
              <p>
                Uniforme <br /> profissional
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img6} alt="" />
              <p>
                Descontos exclusivos: faculdades e combustíveis
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img7} alt="" />
              <p>
                Convênio odontológico: Sest Senat
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`card1 card ${isVisible ? "visible" : ""}`}>
              <img src={img8} alt="" />
              <p>
                Plano de saúde: após experiência
              </p>
            </div>
          </SwiperSlide>
          <div className={`swiper-button-next`}>
            <img src={arrowRight} width={"40px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default SectionThree;
