import React, { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import "swiper/css";

import { Navigation } from "swiper/modules";
import arrowRight from '../../../assets/img/components/arrow-right.webp';

import video1 from "../../../assets/img/career/careerTwo/ana.mp4";
import video2 from "../../../assets/img/career/careerTwo/juliana.mp4";
import video3 from "../../../assets/img/career/careerTwo/marcio.mp4";
import video4 from "../../../assets/img/career/careerTwo/pamela.mp4";
import video5 from "../../../assets/img/career/careerTwo/jessica.mp4";
import video6 from "../../../assets/img/career/careerTwo/thiago.MOV";
import video7 from "../../../assets/img/career/careerTwo/bianca.MOV";

const data = [
    { id: 1, video: video1, name: 'Ana Lúcia', function: 'Operadora de empilhadeira', time: '01 ano de GH'},
    { id: 2, video: video2, name: 'Juliana Luciani Neves', function: 'Gestora financeira', time: '08 anos de GH'},
    { id: 3, video: video3, name: 'Marcio José Alfeu', function: 'Gestor de condutores', time: '11 anos de GH'},
    { id: 4, video: video4, name: 'Pamela Cristina', function: 'Líder de gerenciamento de risco', time: '1 ano de GH'},
    { id: 5, video: video5, name: 'Jéssica Belloto', function: 'Coordenadora Financeira', time: '6 anos de GH'},
    { id: 6, video: video6, name: 'Thiago Andrade', function: 'Conferente', time: '2 ano de GH' },
    { id: 7, video: video7, name: 'Bianca Couto Mattos', function: 'Gerente de RH', time: '6 anos de GH'},
];

function CareerTwo(){

    const [isVisible, setIsVisible] = useState(false);

    const onChange = (visibility) => {
        if (visibility) {
        setIsVisible(true);
        }
    };

    // Refs para os elementos de vídeo
    const videoRefs = useRef(data.map(() => React.createRef()));

    const handleSlideChange = (swiper) => {
        const previousIndex = swiper.previousIndex;
        if (videoRefs.current[previousIndex] && videoRefs.current[previousIndex].current) {
            videoRefs.current[previousIndex].current.pause();
        }
    };

    return(
        <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>
        <div className={`careerTwo ${isVisible ? 'visible' : ''}`}>
            <div className="careerTwoTitle">
                <h1 className={`${isVisible ? 'visible' : ''}`}>Experiências <br /> compartilhadas</h1>
                <p className={`${isVisible ? 'visible' : ''}`}>Na GH, compartilhar experiências é o que nos impulsiona a crescer a cada dia.</p>
            </div>
            <Swiper
                    className={`${isVisible ? 'visible' : ''}`}
                    effect={'fade'}
                    modules={[ Navigation]}
                    navigation={{
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next',
                    }}
                    speed={700}
                    onSlideChange={(swiper) => handleSlideChange(swiper)}
            >
                {data.map((item, index) => (
                <SwiperSlide key={item.id}>
                    <div className="card1">
                        <div className="card-media">
                            <video
                                class="clip"
                                id={`media-video-${index}`}
                                type="video/mp4"
                                rel="preload"
                                controls
                                ref={videoRefs.current[index]}
                            >
                                <source src={item.video} />
                            </video>
                            <div className="media-text text-video">
                                <h1>{item.name}</h1>
                                <h2>{item.function}</h2>
                                <p>{item.time}</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                ))}
                <div className="swiper-button-next"><img src={arrowRight} width={'35px'} alt="" /></div>
            </Swiper>
        </div>
        </VisibilitySensor>
    )
}

export default CareerTwo;