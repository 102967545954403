import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./view/home/home";
import Services from "./view/services/services";
import About from "./view/about/about";
import FleetStructure from "./view/fleetStructure/fleetStructure";
import WarehouseStructure from "./view/warehouseStructure/warehouseStructure";
import Career from "./view/career/career";
import ESG from "./view/esg/esg";
import Blog from "./view/blog/Blog";
import VisiblePubli from "./view/blog/VisiblePubli";
import AllBlogPosts from "./view/blog/AllBlogPosts";
import Contact from "./view/contact/contact";
import AnonymousReports from "./view/anonymousReports/anonymousReports";
import Post from "./view/blog/Post";

import LandingPageOne from "./landingPages/view/landingPageOne/landingPageOne";
import LandingPageTwo from "./landingPages/view/landingPageTwo/landingPageTwo";

function RouteApp() {

  return (
    <BrowserRouter >  
      <Routes>
        <Route path="*" name="Home" element={<Home />} />
        <Route path="/about" name="About" element={<About />} />
        <Route path="/services" name="Services" element={<Services />} />
        <Route
          path="/fleetStructure"
          name="FleetStructure"
          element={<FleetStructure />}
        />
        <Route
          path="/warehouseStructure"
          name="WarehouseStructure"
          element={<WarehouseStructure />}
        />
        <Route path="/career" name="Career" element={<Career />} />
        <Route path="/esg" name="Esg" element={<ESG />} />

        <Route path="/blog" name="Blog" element={<Blog />}>
          <Route
            path="visiblePubli"
            name="VisiblePubli"
            element={<VisiblePubli />}
          />
          <Route
            index={true}
            name="AllBlogPosts"
            element={<AllBlogPosts />}
          />
          <Route
            name="post"
            path="post"
            element={<Post />}
          />
        </Route>
        <Route path="/contact" name="Contact" element={<Contact />} />
        <Route
          path="/anonymousReports"
          name="AnonymousReports"
          element={<AnonymousReports />}
        />

        <Route path="/landingPageOne" name="LandingPageOne" element={<LandingPageOne />} />

        <Route path="/landingPageTwo" name="LandingPageTwo" element={<LandingPageTwo />} />
      
      </Routes>
    </BrowserRouter>

  );
}

export default RouteApp;
