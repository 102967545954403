import React, { useState } from "react";

import VisibilitySensor from "react-visibility-sensor";

import img1 from "../../../../assets/img/home/career/img2.webp";

function SectionTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={500}>
      <div id="about" className={`sectionTwo ${isVisible ? "visible" : ""}`}>
        <div className="arrows1">
          <img
            className={`img1 ${isVisible ? "visible" : ""}`}
            src={img1}
            alt=""
            width={"160px"}
          />
          <img
            className={`img2 ${isVisible ? "visible" : ""}`}
            src={img1}
            alt=""
            width={"160px"}
          />
        </div>
        <div className="text">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Sobre a vaga
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Se você é um motorista com mais de um ano de experiência e reside no 
            Vale do Itajaí, sua oportunidade chegou!
            Na GH, com nossa matriz em Itajaí, é o centro das nossas operações, 
            oferecendo suporte completo e valorizando cada profissional. Aqui, 
            você será parte de uma equipe que lidera o setor logístico com 
            inovação e compromisso.
            Clique no botão abaixo e comece sua jornada com a GH Operador Logístico!
          </p>
          <a href="#contact" className={`${isVisible ? "visible" : ""}`}>
            <button>Candidatar-se</button>
          </a>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default SectionTwo;
