import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Autoplay } from "swiper/modules";

import arrowLeft from "../../../../assets/img/components/arrow-left.webp";
import arrowRight from "../../../../assets/img/components/arrow-right.webp";
import img1 from "../../../assets/img/landingPageTwo/sectionFive/img1.webp";
import img2 from "../../../assets/img/landingPageTwo/sectionFive/img2.webp";
import img3 from "../../../assets/img/landingPageTwo/sectionFive/img3.webp";
import img4 from "../../../assets/img/landingPageTwo/sectionFive/img4.webp";

import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';

const data = [
  {
    id: 1,
    class: "card1",
    title: "Sustentabilidade:",
    img: img1,
    content1: [
        "Veículos EURO 5, EURO 6 e GNV para reduzir o impacto ambiental.",
    ],
  },
  {
    id: 2,
    class: "card2",
    title: "Tecnologia de ponta:",
    img: img2,
    content1: [
        "Garantimos segurança e pontualidade nas entregas.",
    ],
  },
  {
    id: 3,
    class: "card3",
    title: "Idade média da frota:",
    img: img3,
    content1: [
        "Cavalos: 2,7 anos",
    ],
    content2:[
        "Carretas: 3,2 anos",
    ]
  },
];

function SectionFive() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={400}>
      <div id="fleet" className={`sectionFive ${isVisible ? "visible" : ""}`}>
        <div className="sectionFiveTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Frota de <span> Alto Desempenho</span>
          </h1>
          <p>
            Na GH, nossa matriz lidera com uma frota moderna e tecnologicamente avançada.
          </p>
        </div>
        <Swiper
          className={`${isVisible ? "visible" : ""}`}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          effect={'fade'}
          modules={[Navigation, EffectFade, Autoplay]}
          speed={800}
          slidesPerView={"1"}
          spaceBetween={200}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          <div className={`swiper-button-prev`}>
            <img src={arrowLeft} width={"40px"} alt="" />
          </div>
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <div className={`card ${item.class}`}>
                <div className="cardText">
                  <h1 className={`${isVisible ? "visible" : ""}`}>
                    {item.title}
                  </h1>
                  <p className={`text1 ${isVisible ? "visible" : ""}`}>
                    {item.content1}
                  </p>
                  <p className={`text1 ${isVisible ? "visible" : ""}`}>
                    {item.content2}
                  </p>
                </div>
                <img className={`${isVisible ? "visible" : ""}`} src={item.img} alt="" />
              </div>
            </SwiperSlide>
          ))}
          <div className={`swiper-button-next`}>
            <img src={arrowRight} width={"40px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}
export default SectionFive;
