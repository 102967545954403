import React, { useState } from "react";
import { CButton, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro";

import VisibilitySensor from "react-visibility-sensor";

import { sendContactEmail } from "../../../../services/sendContactEmail";
import { useAlert } from "../../../../contexts/AlertContext";

function SectionFour() {

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const defaultFormData = {
    name: '',
    enterprise: '',
    email: '',
    phone: '',
    message: '',
    setor: '',


    isLoadingResponse: false
  }

  const [formData, setFormData] = useState(defaultFormData)

  const formatPhoneValue = (event) => {
    const formattedPhone = event.target.value
      .replace(/\D/g, '') // Remove todos os caracteres não numéricos
      .replace(/^(\d{2})(\d)/g, '($1) $2') // Adiciona parênteses em volta dos dois primeiros dígitos
      .replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona hífen entre o quinto e o sexto dígitos

    setFormData(prev => ({ ...prev, phone: formattedPhone }))
  }

  const {showAlert} = useAlert()

  const handleSubmit = async () => {

    setFormData(prev => ({ ...prev, isLoadingResponse: true }))


    const response = await sendContactEmail(formData)

    if (response.success) {
      setFormData(defaultFormData)
    }

    showAlert(response.message, response.success ? 'success' : 'error')
    setFormData(prev => ({ ...prev, isLoadingResponse: false }))
  }


  return (
    <VisibilitySensor onChange={onChange} partialVisibility minTopValue={100}>
      <div id="contact" className={`sectionFour ${isVisible ? 'visible' : ''}`}>
        <div className="sectionFourText">
          <h1 className={`${isVisible ? 'visible' : ''}`}>Preencha o formulário <br /> ao lado para se candidatar</h1>
          <p>Venha dirigir para um operador logístico que cuida da sua segurança e valoriza o seu esforço. Vem para GH .</p>
        </div>
        <CForm className={`${isVisible ? 'visible' : ''}`}>
          <div>
            <CFormLabel className="me-2 labelName">Nome completo:</CFormLabel>
            <CFormInput
              type="text"
              maxLength={80}
              minLength={3}
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="me-2"
              placeholder=""
            ></CFormInput>
            <CFormLabel>E-mail:</CFormLabel>
            <CFormInput
              type="email"
              autoComplete='off'
              maxLength={60}
              className="me-2"
              placeholder=""
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
            ></CFormInput>
            <CFormLabel>Telefone:</CFormLabel>
            <CFormInput
              type="phone"
              autoComplete='off'
              maxLength={15}
              minLength={15}
              onChange={formatPhoneValue}
              className="me-2"
              placeholder=""
              value={formData.phone}
            ></CFormInput>
            <CFormLabel>Cidade:</CFormLabel>
            <CFormInput
              type="text"
              autoComplete='off'
              maxLength={40}
              className="me-2"
              placeholder=""
              value={formData.city}
              onChange={(e) => setFormData(prev => ({ ...prev, city: e.target.value }))}
            ></CFormInput>
            <CFormLabel>Você tem experiência com carreta?</CFormLabel>
            <CFormSelect
                aria-label="Você tem experiência com carreta?"
                options={[
                    { label: '' },
                    { label: 'Sim', value: 'sim' },
                    { label: 'Não', value: 'nao' },
                ]}
                value={formData.experience}
                onChange={(e) => setFormData(prev => ({ ...prev, experience: e.target.value }))}
            />
            <CFormLabel>Você tem quantos anos de experiência com carreta?</CFormLabel>
            <CFormSelect
                aria-label="Você tem quantos anos de experiência com carreta?"
                options={[
                    { label: '' },
                    { label: 'Mais de 1 ano', value: '1' },
                    { label: 'Menos de 1 ano', value: '2' },
                ]}
                value={formData.yearsOfExperience}
                onChange={(e) => setFormData(prev => ({ ...prev, yearsOfExperience: e.target.value }))}
            />
            <CFormLabel>Você aceita trabalhar cumprindo uma velocidade máxima de 80 km/h?</CFormLabel>
            <CFormSelect
                aria-label="Você aceita trabalhar cumprindo uma velocidade máxima de 80 km/h?"
                options={[
                    { label: '' },
                    { label: 'Sim', value: 'sim' },
                    { label: 'Não', value: 'nao' },
                ]}
                value={formData.speed}
                onChange={(e) => setFormData(prev => ({ ...prev, speed: e.target.value }))}
            />

            <CButton className="mt-4" onClick={handleSubmit} disabled={formData.isLoadingResponse}>
              {formData.isLoadingResponse ? 'Enviando...' : 'Enviar'}
            </CButton>
          </div>
        </CForm>
      </div>
    </VisibilitySensor>
  )
}
export default SectionFour;