import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CNavbarBrand,
  CContainer,
  CNavbarToggler,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CNavbarNav,
  CNavItem,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react-pro";

import logo from "src/assets/icon/logo.png"

function Navbar() {
  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CNavbar
      className={`navLandingPage py-4 ${scrolled ? "scrolled" : ""}`}
      expand="xl"
      translate="no"
    >
      <a href="www.ghlogistica.com.br" className="back">Ir para o site <div className="underline"></div></a>
      <div className="nav-container">
        <CContainer fluid>
          <CNavbarBrand className={`navLogo2`}>
            <a href="/">
              <img src={logo} alt="logo" width={200} />
            </a>
          </CNavbarBrand>
          <CNavbarToggler
            aria-controls="offcanvasNavbar2"
            aria-label="Toggle navigation"
            onClick={() => setVisible(!visible)}
          />
          <COffcanvas
            id="offcanvasNavbar2"
            placement="end"
            portal={false}
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <COffcanvasHeader>
              <COffcanvasTitle></COffcanvasTitle>
              <CCloseButton
                className="text-reset"
                onClick={() => setVisible(false)}
              />
            </COffcanvasHeader>
            <COffcanvasBody>
              <div className="flex-content-nav">
                <CNavbarNav>
                  <CNavItem className={`px-1 aboutLink`}>
                    <a href="#about" active reloadDocument>
                      Saber mais
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 benefitsLink`}>
                    <a href="#benefits" active reloadDocument>
                      Beneficios
                    </a>
                  </CNavItem>
                </CNavbarNav>
                <CNavbarBrand className={`navLogo`}>
                  <img src={logo} alt="logo" width={230} />
                </CNavbarBrand>
                <CNavbarNav>
                  <CNavItem className={`px-1 subscribeLink`}>
                    <a href="#contact" active reloadDocument>
                      Increver-se
                    </a>
                  </CNavItem>
                  <CNavItem className={`px-1 fleetLink`}>
                    <a href="#fleet" active reloadDocument>
                      Frota
                    </a>
                  </CNavItem>
                </CNavbarNav>
              </div>
            </COffcanvasBody>
          </COffcanvas>
        </CContainer>
      </div>
    </CNavbar>
  );
}

export default Navbar;
